import React from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';

export interface Props {
  subject?: string;
  children?: React.ReactNode;
}

const NotFound404: React.FC<Props> = ({ subject, children }) => (
  <ErrorPage
    errorDescription={`The ${subject || 'page'} you are looking for cannot be found`}
    errorMessage={'Error 404'}
  >
    {children}
  </ErrorPage>
);
export default NotFound404;
